export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: "foden dev",
  author: "the Foden devs",
  description:
    "A clone of Dan Abramov's overreacted.io using create-react-app-mdx and Navi from https://twitter.com/james_k_nelson/",

  // The number of posts to a page on the site index.
  indexPageSize: 10
};
